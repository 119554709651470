import React from "react";
//import Quit from "../../components/Quit";
// import LanguageSelect from "../../components/LanguageSelect";
// import {useTranslation} from "react-i18next";

function Footer(props) {

  // const { t } = useTranslation();

  return (
    <>
    </>
  );
}

export { Footer };
