import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { addToken } from "./actions/TokenAction";
import { removeRoot } from "./actions/RootAction";
import { removeOldProgress } from "./actions/OldProgressAction";
import $ from 'jquery';
import _debug from "../debug"
import Position from "./Position";
import KeepAlive from "./KeepAlive"
import {Ripple} from "react-awesome-spinners";
import HealthCheck from "./HealthCheck";
import { addMessage } from './actions/MessageAction';
const theming = require('../themes/' + process.env.REACT_APP_THEME + '/theme');

function Dispenser(props) {

  const theme = theming.useTheme();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [reinit, setReinit] = useState(null);

  useEffect(() => {

    function getTokenInfos(uuid, retries, delay) {
      _debug('Get token infos !');
      $.ajax({
        url: process.env.REACT_APP_API_URL + "/token/" + uuid,
        type: 'GET',
        headers: {
          'Accept': 'application/json',
        },
        success: function (data) {
          // Set message in store
          props.addMessage({message : data.message})
          delete data.message
          // Set token in store
          props.addToken(data)
          setIsLoaded(true)
        },
        error: function (xhr, textStatus, errorThrown) {
          _debug(xhr.status)
          _debug(textStatus)
          _debug(errorThrown)
          _debug(retries);
          if(retries > 0) {
            setTimeout(function () {
              getTokenInfos(uuid, --retries, delay);
            }, delay);
          } else {
            setError({message: "Queue unavailable"});
            setIsLoaded(true);
          }
        }
      });
    }

    function getToken(retries, delay) {
      _debug('Get token !');
      $.ajax({
        url: process.env.REACT_APP_API_URL + "/token",
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({instance: process.env.REACT_APP_INSTANCE_ID}),
        success: function (data) {
          _debug(data)
          // Delay to allow time for the api to create the token in database
          if (data.uuid) {
            _debug("Tempo 4s !")
            setTimeout(function () {
              getTokenInfos(data.uuid, 4, 4000);
            }, 4000);
          }
        },
        error: function (xhr, textStatus, errorThrown) {
          _debug(xhr.status)
          _debug(textStatus)
          _debug(errorThrown)
          _debug(retries)
          if(retries > 0) {
            setTimeout(function () {
              getToken(--retries, delay);
            }, delay);
          } else {
            setError({message: "Queue unavailable"});
            setIsLoaded(true);
          }
        }
      });
    }

    function localTokenRecheck() {
      sessionStorage.setItem('localTokenChecked', '1')
      const uuid = props.token.uuid
      _debug('Local token recheck : ' + uuid)
      var check = false
      $.ajax({
        url: process.env.REACT_APP_API_URL + "/token/" + uuid,
        type: 'GET',
        headers: {
          'Accept': 'application/json',
        },
        async: false,
        success: function (data) {
          if (data.status === undefined || (data.status < 0)) {
            _debug('Local token recheck : Token status invalid (' + data.status + ') -> Clean store and reload page !')
            setReinit(true)
          } else {
            _debug('Local token recheck : OK -> use it !')
          }
        },
        error: function (xhr, textStatus, errorThrown) {
          if (parseInt(xhr.status) === 400) {
            _debug('Local token recheck : API Error 400 : Maybe the token does not exist anymore in db -> Clean store and reload page !')
            setReinit(true)
          } else {
            _debug("Local token check : API error detected (" + textStatus + ") but it's not clear -> use local token")
          }
        }
      })
    }

    function initializeConnection() {
      getToken(4, 4000)
      // New token = reset root
      props.removeRoot()
      // New token = reset oldProgress
      props.removeOldProgress()
    }

    if ($.isEmptyObject(props.token)) {
      initializeConnection()
    } else {
      if (typeof sessionStorage === 'object') {
        if (sessionStorage.getItem('localTokenChecked') !== '1') {
          _debug('Local token must be re-checked')
          localTokenRecheck()
        }
        else {
          _debug('Local token already checked.')
        }
      }
      else {
        _debug('sessionStorage is not available !')
      }
      setIsLoaded(true);
    }

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (reinit) {
    window.location.href = '/reinit' + window.location.search
    return (<div style={{textAlign: 'center'}}><Ripple color={theme.rippleColor} /></div>)
  }
  if (error) {
    return <div style={{textAlign: 'center'}}>Erreur : {error.message}</div>;
  } else if (!isLoaded) {
    return (<div style={{textAlign: 'center'}}><Ripple color={theme.rippleColor} /></div>);
  } else {
    return (
      <>
        <Position />
        <KeepAlive />
        <HealthCheck />
      </>
    )
  }

}

const mapStateToProps = state => ({
  token: state.token,
});

const mapDispatchToProps = dispatch => {
  return {
    addToken: token => dispatch(addToken(token)),
    removeRoot: () => dispatch(removeRoot()),
    removeOldProgress: () => dispatch(removeOldProgress()),
    addMessage: message => dispatch(addMessage(message))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dispenser);
